import gsap from "gsap";
import { TextPlugin } from "gsap/all";
import React, { useLayoutEffect } from "react";
import ScrollDown from "../../assets/lottie/scroll-down.json";
import useScreenSize from "../../hooks/WindowSize";
import {
  BackgroundLandingBottom,
  Div,
  LandingComponentContainer,
  LandingComponentContentCotnainer,
  LandingComponentTitleContainer,
  LandingPageComponnetTitle,
  LandingPageText,
  LandingPageTextContainer,
  LottieWrapper,
} from "../../style/home.style";
import { delay } from "../../utils/Function";
import LottieContainer from "../lottie/LottieContainer";
gsap.registerPlugin(TextPlugin);

export default function LandingComponent() {
  const windowWidth = useScreenSize().width;
  // Convert SVG component to string
  useLayoutEffect(() => {
    // Start the animation
    delayHOF(0);
  }, []);
  async function delayHOF() {
    await delay(1800);
    return animateWord(0);
  }

  function animateWord(index) {
    const words = ["Promote", "Expand", "Elevate", "Prexel"];
    const app = document.querySelector(".text");
    if (app === null) return;
    const word = words[index];

    const wordContainer = document.createElement("div");
    // wordContainer.style.position = "absolute";
    // wordContainer.style.top = "50%";
    // wordContainer.style.left = "24.5%";
    // wordContainer.style.transform = "translate(-50%, -50%)";
    app.appendChild(wordContainer);

    word.split("").forEach((letter, letterIndex) => {
      const letterSpan = document.createElement("span");
      letterSpan.style.opacity = "0";
      letterSpan.style.display = "inline-block";
      letterSpan.textContent = letter;
      wordContainer.appendChild(letterSpan);

      gsap.fromTo(
        letterSpan,
        {
          opacity: 0,
          x: -20,
        },
        {
          duration: 1,
          opacity: 1,
          x: 0,
          ease: "power2.out",
          delay: letterIndex * 0.1,
          onComplete: () => {
            if (letterIndex === word.length - 1) {
              gsap.fromTo(
                wordContainer,
                {
                  opacity: 1,
                  x: 0,
                },
                {
                  duration: 0.5,
                  delay: index === words.length - 1 ? 3 : 0,
                  opacity: 0,
                  onComplete: () => {
                    app.removeChild(wordContainer);
                    if (index < words.length - 1) {
                      animateWord(index + 1);
                    } else {
                      animateWord(0);
                    }
                  },
                }
              );
            }
          },
        }
      );
    });
  }

  return (
    <LandingComponentContainer>
      {/* <Suspense fallback="loading">
        <Spline scene="https://prod.spline.design/RypyMhdo-LHtBD2q/scene.splinecode" />
      </Suspense> */}

      <LandingComponentContentCotnainer>
        <LandingComponentTitleContainer>
          <LandingPageComponnetTitle>
            <div className="we">We</div>
            <Div>
              <span class="text" style={{ fontSize: "1.05em" }}></span>
              <span class="text-cursor" style={{ opacity: 0 }}>
                |
              </span>
            </Div>
          </LandingPageComponnetTitle>
        </LandingComponentTitleContainer>
        <LandingPageTextContainer>
          <LottieWrapper
            style={{ display: windowWidth < 600 ? "none" : "block" }}
            id="lottie-wrapper"
          >
            <LottieContainer
              animationData={ScrollDown}
              height={50}
              width={50}
              color="white"
            />
          </LottieWrapper>
          <LandingPageText id="landing-page-text">
            We are a consulting firm Promoting, Expanding, and Elevating people
            and businesses across the world in the digital and real world with
            our IT, Recruitment, Business Expansion, and Accounting Services.
          </LandingPageText>
        </LandingPageTextContainer>
        {/* <MobileContentContainer>
          <TextContainer
            $fontSize={"3em"}
            $fontWeight={"400"}
            $width="80%"
            $color={CommonColor.white}
            $margin="10% 0 0 0"
          >gres for your growth and success.
          </TextContainer>
          <ButtonContainerRipple>
            <MuiButtonGetInTouch onClick={() => {}}>
              Get in Touch
            </MuiButtonGetInTouch>
          </ButtonContainerRipple>
        </MobileContentContainer> */}
        <LottieWrapper
          style={{
            display: windowWidth < 600 ? "block" : "none",
            position: "absolute",
            bottom: 0,
          }}
        >
          <LottieContainer
            animationData={ScrollDown}
            height={50}
            width={50}
            color="white"
          />
        </LottieWrapper>
        <BackgroundLandingBottom
          style={{ bottom: -50 }}
        ></BackgroundLandingBottom>
      </LandingComponentContentCotnainer>
    </LandingComponentContainer>
  );
}
